.TeamPage {
    .team-page-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        column-gap: 10px;
        row-gap: 10px;
        max-height: 640px;

        .team-name-section {
            grid-column: span 3;
            justify-self: start;
            align-self: end;

            .team-name {
                font-size: 4rem;
            }
        }

        .win-loss-section {
            justify-self: center;
            align-self: center;
        }

        .match-detail-section {
            grid-column: span 4;
        }

        .match-small-section {
            display: flex;
        }

        .more-link {
            justify-self: center;
            align-self: center;
            font-size: 1.4rem;
        }
    }
}
.MatchDetailCard{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 5px;
    margin-bottom: 30px;
    padding: 10px;
    .match-date, .match-venue, .match-toss-winner{
        margin-top: 20px;
    }
    .match-detail-tile2{
        text-align: end;
        & > h4 {
            margin-top: 7px;
        }
    }
}
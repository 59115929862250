.HomePage{
    .heading-section{
        font-size: 2rem;
        margin-left: 30px;
    }
    .team-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        & > div{
            background-color: #3c3838;
            border-radius: 30px;
            padding: 30px;
        }
    }
}